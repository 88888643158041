<template>
  <div>
    <sheet-link :client-id="clientId" :sheet-link="localSheetLinks.attributes" sheet-template-type="ATTRIBUTES" label="planilha de atributos" />
  </div>
</template>

<script>
export default {
  name: 'SheetLinks',
  components: {
    SheetLink: () => import('@/modules/retailers/views/components/misc/SheetLink.vue')
  },
  props: {
    clientId: {
      type: String,
      required: true
    },
    sheetLinks: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    localSheetLinks: {},
    loading: false
  }),
  watch: {
    sheetLinks(val) {
      this.localSheetLinks = val
    }
  },
  mounted() {
    if (this.sheetLinks) {
      this.localSheetLinks = this.sheetLinks
    }
  }
}
</script>
